document.addEventListener('DOMContentLoaded', function () {
    WebflowCustomCode.initAgentSelection();
    WebflowCustomCode.initAgencySelection();
    WebflowCustomCode.agentSelectionReset();
});

window.WebflowCustomCode = (function () {

    function addHiddenTrackingFields(form) {
        const user = analytics.user ? analytics.user() : null;
        const pageTitleCleaned = document.title.split("|")[0].trim();
        const userTraits = getFromLocalStorage('ajs_user_traits');

        let values = {
            url: window.location.origin + window.location.pathname,
            query: window.location.search,
            "segment_user_id": user ? user.id() : "",
            "segment_anonymous_id": user ? user.anonymousId() : "",
            "current_page": pageTitleCleaned,
            referrer: document.referrer,
            "user_agent": navigator.userAgent,
            "marketing_data": JSON.stringify(
                ClickCollector.getClickIds()
            ),
            email: Object.keys(userTraits).length > 0 ? userTraits['email'] : '',
        };

        Object.entries(values).forEach(([name, value]) =>
            setHSInputValue(form, name, value)
        );
    }

    function setMarketingDataValue() {
        const hsMarketingDataInput = document.querySelector(
            'input[name="marketing_data"]'
        );

        if (hsMarketingDataInput) {
            hsMarketingDataInput.value = JSON.stringify(
                ClickCollector.getClickIds()
            );
        }
    }

    function setServicePrioritySelection($form) {
        const selects = $form.find('select[name*="service"]');

        selects.on('change', function () {
            selects.find('option').prop('disabled', false);
            selects.each(function () {
                const selectedValue = $(this).val();
                if (selectedValue) {
                    selects.not(this).find('option[value="' + selectedValue + '"]').prop('disabled', true);
                }
            });
        });
    }

    function setChangeAgencyCustomCode($form) {
        $form.find('[name="TICKET.marketing_data"]')
        const dateOfNoticeElem = $form.find('[name="TICKET.date_of_notice_period"]');
        if (dateOfNoticeElem) dateOfNoticeElem.prop('disabled', true);

        const submissionData = localStorage.getItem('submissionData');
        if (submissionData) {
            const data = JSON.parse(submissionData);
            if (data.agencySelected && data.agentSelected) {
                $form.find('input[name="TICKET.new_agent"]').val(`${data.agencySelected} - ${data.agentSelected}`).change();
            }

            $form.find('input[name="TICKET.current_managing_agent"]').on("change", function () {
                $form.find('input[name="TICKET.subject"]').val(`${$(this).val()} - ${data.agentSelected}`).change();
            });
        }
    }

    function initAgentSelection() {
        if (!window.location.pathname.includes('agent-selection')) return;

        const submissionData = getFromLocalStorage('submissionData');
        if (submissionData && submissionData.agencySelected) {
            const labels = document.querySelectorAll('span[ll-element="filter-radio-label"]');
            for (let i = 0; i < labels.length; i++) {
                if (labels[i].textContent.trim() === submissionData.agencySelected) {
                    const radioButton = labels[i].parentNode.querySelector('input[type="radio"]');
                    if (radioButton) {
                        radioButton.checked = true;
                        break;
                    }
                }
            }
        }

        const buttons = document.querySelectorAll('[ll-agent-select-button]');
        buttons.forEach(button => {
            button.addEventListener('click', function () {
                const existingData = JSON.parse(localStorage.getItem('submissionData')) || {};
                const agentName = this.getAttribute('ll-agent-name');

                existingData.agentSelected = agentName;

                setToLocalStorage('submissionData', existingData);
            });
        });
    }

    function initAgencySelection() {
        if (!window.location.pathname.includes('agency-selection')) return;

        const buttons = document.querySelectorAll('[ll-agency-select-button]');
        buttons.forEach(button => {
            button.addEventListener('click', function () {
                const attributeValue = this.getAttribute('ll-agency-select-button');
                const existingData = JSON.parse(localStorage.getItem('submissionData')) || {};
                existingData.agencySelected = attributeValue;
                
                setToLocalStorage('submissionData', existingData);
            });
        });
    }

    function agentSelectionReset() {
        document.querySelectorAll('[ll-element="start-over"]').forEach(element => {
            element.addEventListener('click', () => {
                localStorage.removeItem('submissionData');
            });
        });
    }

    return {
        setMarketingDataValue,
        addHiddenTrackingFields,
        setServicePrioritySelection,
        setChangeAgencyCustomCode,
        initAgentSelection,
        initAgencySelection,
        agentSelectionReset,
    };
})();