function setHSInputValue(form, name, value) {
    if (!form || value == null) return;
    const hsInput = form.find(`input[name*="${name}"]`);
    
    if (hsInput.length > 0 && value.trim() != "") {
        hsInput.val(value).change();
    }
}

function getFirstLastName(traits, fullName) {
    if (typeof fullName === "string" && fullName != "") {
        const first_name = fullName.split(" ").slice(0, -1).join(" ");
        const last_name = fullName.split(" ").slice(-1).join(" ");

        return { ...traits, first_name, last_name };
    }

    return traits;
}

function splitName(fullName) {
    const trimmedName = fullName.trim();

    if (trimmedName === "") {
        return {
            firstName: "",
            lastName: "",
        };
    }

    const [firstName, ...lastNameArray] = trimmedName.split(" ");
    const lastName = lastNameArray.join(" ");

    return {
        firstName,
        lastName,
    };
}

function hashString(str) {
    let hash = 0;

    if (str.length === 0) {
        return hash.toString(16);
    }

    for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
    }

    return (hash >>> 0).toString(16);
}

function getLeadSubmittedProps(
    firstName,
    lastName,
    email,
    phone,
    formId,
    formData = {}
) {

    return {
        first_name: firstName,
        last_name: lastName,
        email,
        phone: phone ? standardisedPhoneNumber(phone) : "",
        lead_type: "form",
        form_id: formId,
        form_data: formData,
    };
}

function formDataToObject(formData) {
    return Object.fromEntries(formData.entries());
}

function getFromLocalStorage(key) {
    if (!localStorage.getItem(key)) return {};

    return JSON.parse(localStorage.getItem(key));
}

function setToLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function setItemWithTTL(key, value, ttlHours) {
    const now = new Date();
    const ttlMilliseconds = ttlHours * 60 * 60 * 1000;
    const item = {
        value: value,
        expiry: now.getTime() + ttlMilliseconds,
    };
    
    localStorage.setItem(key, JSON.stringify(item));
}

function getItemWithTTL(key) {
    const itemStr = localStorage.getItem(key);
    
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

function setToSessionStorage(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
}

function convertDatetimeToTimeString(datetime) {
    const date = new Date(datetime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return formattedTime;
}

function standardisedPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.replace(/[ ]/g, "").replace("(0)", "");
    phoneNumber = phoneNumber.replace(/^00/, "+");
    phoneNumber = phoneNumber.replace(/[^\d\+]/g, "").trim();

    if (!phoneNumber.startsWith("+")) {
        phoneNumber = "+" + phoneNumber;
    }

    return phoneNumber == "+" ? "" : phoneNumber;
}

function toSnakeCase(str) {
    str = str.toLowerCase();
    return str
        .replace(/[\s-]+/g, '_')
        .replace(/_+/g, '_');
}
