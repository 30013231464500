let hasCollectedIds = false;
const clickCollectionKey = "ikrs:click_collection";
const clickIdsKey = "ikrs:click_ids";

const ClickCollector = (function () {
    const defaultClickIds = ["gclid", "fbclid", "mscklid", "client_id"];

    function getHasCollectedIds() {
        return hasCollectedIds;
    }

    function getClickIds() {
        return clickCollectionKey in localStorage
            ? getFromLocalStorage(clickCollectionKey)
            : { first: {}, last: {} };
    }

    async function fetchClicksIdsJSON() {
        const clickIds = getItemWithTTL(clickIdsKey);

        if (clickIds != null) return clickIds;

        try {
            const url = "https://cdn2.ikaros.io/client/internal/project/web/staging/js/click-config.json";
            const response = await fetch(url);
            
            if (response.ok) {
                return response.json();
            }
        } catch (error) {
            console.error('Fetching click-config.json failed. Using default click ids instead.');
            return { click_ids: defaultClickIds };
        }
    }

    function recordClickIdsData(clickIds) {
        if (typeof clickIds === 'undefined') clickIds = defaultClickIds;

        let clickIdsObj = getClickIds();

        const urlParams = new URLSearchParams(window.location.search);

        urlParams.forEach((value, key) => {
            if (value && (clickIds.includes(key) || key.startsWith("utm_"))) {
                if (!(key in clickIdsObj.first)) {
                    clickIdsObj.first[key] = value;
                }

                clickIdsObj.last[key] = value;
            }
        });

        localStorage.setItem(clickCollectionKey, JSON.stringify(clickIdsObj));
        hasCollectedIds = true;
    }

    return {
        recordClickIdsData,
        getHasCollectedIds,
        getClickIds,
        fetchClicksIdsJSON,
    };
})();

ClickCollector.fetchClicksIdsJSON().then(data => {
    setItemWithTTL(clickIdsKey, data, 24);
    ClickCollector.recordClickIdsData(data['click_ids']);
});