window.SegmentEvent = (function () {

    function analyticsIdentify(traits, context = {}) {
        try {
            if (typeof analytics?.trackForm === "function") {
                analytics.identify(traits, { context });
            } else {
                throw new Error("Segment analytics was not initialized.");
            }
        } catch (e) {
            console.error(e);
        }
    }

    function trackLeadSubmitted(traits) {
        analyticsTrack("Lead Submitted", traits);
    }

    function analyticsTrack(eventName, traits) {
        try {
            if (typeof analytics?.track === "function") {
                analytics.track(eventName, traits);
            } else {
                throw new Error("Segment analytics was not initialized.");
            }
        } catch (e) {
            console.error(e)
        }
    }

    function hasSegmentInitialized() {
        return analytics && analytics.initialized;
    }

    return {
        trackLeadSubmitted,
        hasSegmentInitialized,
        analyticsIdentify,
    };
})();