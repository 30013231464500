window.addEventListener("DOMContentLoaded", (event) => {
    HubspotFormEventListener.listenForHubspotMessages();
});

const HubspotFormEventListener = (function () {

    function listenForHubspotMessages() {
        window.addEventListener('message', function (event) {
            const { data } = event;

            if (data.type === 'hsFormCallback') {
                const { id: formId, data: eventData, eventName } = data;
                
                if (eventName === 'onFormSubmitted') {
                    personalInfoOnSubmit(formId, eventData);
                    servicePriortyOnSubmit(formId, eventData);
                }
            }
        });
    }

    function agencyChangeOnSubmit(formId, eventData) {
        if (formId != 'df983fba-e110-4b57-8ac8-8d8726989742' || eventData == null) return;
    }

    function servicePriortyOnSubmit(formId, eventData) {
        if (formId != 'cf1efa9a-0ea0-489e-8308-83768897b733' || eventData == null) return;

        const existingData = getFromLocalStorage('submissionData');
        const { submissionValues } = eventData;
        
        existingData.servicePriorityTop1 = submissionValues['service_priority_top_1'];
        existingData.servicePriorityTop2 = submissionValues['service_priority_top_2'];
        existingData.servicePriorityTop3 = submissionValues['service_priority_top_3'];

        setToLocalStorage('submissionData', existingData);
    }

    function personalInfoOnSubmit(formId, eventData) {
        if (formId != '263a46c0-9a9e-495a-b2bc-0fc722d29bba' || eventData == null) return;

        const { submissionValues } = eventData;
        const formData = {
            first_name: submissionValues['firstname'],
            last_name: submissionValues['lastname'],
            email: submissionValues['email'],
            phone: submissionValues['phone'],
        };

        setToLocalStorage('submissionData', formData);

        const properties = {
            ...formData,
            url: submissionValues['url'],
            query: submissionValues['query'],
            current_page: submissionValues['page'],
            segment_user_id: submissionValues['segment_user_id'],
            segment_anonymous_id: submissionValues['segment_anonymous_id'],
            referrer: submissionValues['referrer'],
            user_agent: submissionValues['user_agent'],
            marketing_data: submissionValues['marketing_data'],
            lead_type: 'hubspot_form',
        }

        SegmentEvent.analyticsIdentify(formData);
        SegmentEvent.trackLeadSubmitted(properties);
    }

    return {
        listenForHubspotMessages,
    };
})();